import $ from 'jquery';

$(() => {
  const bannerCarousel = $('#banner-carousel')[0];
  // const body = $('body');
  const darkMode = $('html').hasClass('dark');
  const darkPreference = window.matchMedia('(prefers-color-scheme: dark)');
  const header = $('#site-header');
  const html = $('html');
  const logo = $('.header-logo img');
  const navIcons = $('.nav-icon');
  const menuBtn = $('#hamburger-menu');
  const navLinks = $('.nav-link');

  let mobileNavOpen = false;

  // Dark Mode
  if (!darkMode) {
    navIcons.removeClass('invert-icons');
  }

  function toggleDarkMode() {
    if (darkMode) {
      localStorage.theme = 'light';
      $('#white-logo').toggleClass('hidden');
      $('#black-logo').toggleClass('hidden');
      navIcons.toggleClass('invert-icons');
    } else {
      localStorage.theme = 'dark';
      $('#white-logo').toggleClass('hidden');
      $('#black-logo').toggleClass('hidden');
      navIcons.toggleClass('invert-icons');
    }
    html.toggleClass('dark');
  }

  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', (e) => {
      e.preventDefault();
      if (
        (!darkMode && darkPreference.matches) ||
        (darkMode && !darkPreference.matches)
      ) {
        toggleDarkMode();
      }
    });
  $('#dark-mode-btn').on('click', function (e) {
    e.preventDefault();
    toggleDarkMode();
  });

  // Navbar
  function hideNav() {
    header.removeClass('bg-white dark:bg-gray-900');
    logo.css({ opacity: '0' });
    navLinks.css({ color: '#cacaca' });
    navIcons.css({
      '-webkit-filter': 'invert(90%)',
      filter: 'invert(90%)',
    });
  }
  function showNav() {
    header.addClass('bg-white dark:bg-gray-900');
    navLinks.removeAttr('style');
    navIcons.removeAttr('style');
    logo.removeAttr('style');
  }
  // Hide/Show Nav 
  // if ($(window).scrollTop() === 0 && bannerCarousel) {
  //   hideNav();
  // }
  // $(window).scroll(function () {
  //   if ($(window).scrollTop() === 0 && bannerCarousel) {
  //     hideNav();
  //   } else {
  //     showNav();
  //   }
  // });

  // Mobile Nav
  function hideMobileMenu() {
    if (bannerCarousel && $(window).scrollTop() === 0) {
      hideNav();
    }
    $('#mobile-menu').addClass('hidden');
    $('body').removeClass('modal-active');
  }

  function showMobileMenu() {
    if (bannerCarousel) {
      showNav();
    }
    $('#mobile-menu').removeClass('hidden');
    $('body').addClass('modal-active');
  }

  menuBtn.on('click', function (e) {
    e.preventDefault();
    if (mobileNavOpen) {
      hideMobileMenu();
      mobileNavOpen = false;
    } else {
      showMobileMenu();
      mobileNavOpen = true;
    }
  });
});
