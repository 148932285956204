import Alpine from "alpinejs";

window.document.addEventListener("DOMContentLoaded", function () {
    window.console.log("🛼 DOM ready app.js");

    window.Alpine = Alpine;

    Alpine.store("darkMode", {
        init() {
            this.isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
        },

        isDark: false,

        toggle() {
            this.isDark = !this.isDark;
            console.log(`Dark Mode: ${this.isDark}`);
        },
    });

    Alpine.start();
});
